<template>
    <div class="placed-order">

        <section class="bgimage d-flex justify-content-center align-items-center text-center">
            <b-container>
                <b-row v-if="order">
                    <b-col v-if="order.status.name == 'pending'">
                        <h1>Er is een fout opgetreden tijdens de betaling...</h1>
                        <h3 class="text-white">Probeer het opnieuw.</h3>
                    </b-col>
                    <b-col v-else>
                        <h1>Bedankt voor je bestelling</h1>
                        <h3 class="text-white">Volg hieronder de status</h3>
                    </b-col>
                </b-row>
                <b-row v-else>
                    <b-col>
                        <h1>Geen bestelling gevonden.</h1>
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <section v-if="order && order.status.name != 'pending'" class="p-5 my-5">
            <b-row>
                <b-col class="d-flex align-items-center" cols="12" lg="6">
                    <b-container class="text-center mb-5">
                        <h3>Status:</h3>
                        <h1 v-if="order.status.name == 'paid'">{{ 'In behandeling' }}</h1>
                        <h1 v-if="order.status.name == 'confirmed'">{{ 'Bevestigd' }}</h1>
                        <h1 v-if="order.status.name == 'shipped'">{{ 'Onderweg' }}</h1>
                        <h1 v-if="order.status.name == 'completed'">{{ 'Bezorgd' }}</h1>
                    </b-container>
                </b-col>

                <b-col cols="12" lg="6">
                    <b-container class="my-3">
                        <h4>Bestelling</h4>
                        <hr>
                        <!-- Orderlines -->
                        <b-row class="mt-4" v-for="order_line in order.order_lines" :key="order_line._id">
                            <b-col lg="12">

                                <!-- Main Line -->
                                <b-row class="font-weight-bold">
                                    <b-col col cols="1">
                                        <span>{{order_line.quantity}}x</span>
                                    </b-col>
                                    <b-col col>
                                        <span>{{order_line.name}}</span>
                                    </b-col>
                                    <b-col col class="ml-auto text-right">
                                        <span>&euro;{{((order_line.quantity * order_line.price) / 100).toFixed(2)}}</span>
                                    </b-col>
                                </b-row>

                                <!-- Menu Lines -->
                                <div v-if="order_line.menu">
                                    <b-row
                                            v-for="menu_line in order_line.menu.menu_lines"
                                            :key="menu_line._id"
                                    >
                                        <b-col col cols="1" offset="1">
                                            <small>{{menu_line.quantity}}x</small>
                                        </b-col>
                                        <b-col col>
                                            <small>{{menu_line.product.name}}</small>
                                        </b-col>
                                    </b-row>
                                </div>

                                <div v-if="order_line.menu_rules">
                                    <b-row
                                            v-for="rule in order_line.menu_rules"
                                            :key="rule._id"
                                    >
                                        <b-col col cols="1" offset="1">
                                            <small>{{rule.quantity}}x</small>
                                        </b-col>
                                        <b-col col>
                                            <small>{{rule.name}}</small>
                                        </b-col>

                                        <!-- Menu rule product lines -->
                                        <b-col
                                                col
                                                cols="12"
                                        >
                                            <b-row
                                                    v-for="value in rule.products"
                                                    :key="value._id"
                                            >
                                                <b-col col cols="1" offset="2">
                                                    <small>{{value.quantity}}x</small>
                                                </b-col>
                                                <b-col col>
                                                    <small>
                                                        {{value.product.name}}
                                                    </small>
                                                </b-col>
                                                <b-col col class="ml-auto text-right">
                                                    <small v-if="value.product.extra_price">
                                                        + &euro;{{((value.product.extra_price * value.quantity) /
                                                        100).toFixed(2)}}
                                                    </small>
                                                </b-col>
                                            </b-row>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-col>
                        </b-row>

                        <hr>
                        <b-row class="py-1">
                            <b-col col>
                                Subtotaal
                            </b-col>
                            <b-col col lg="3" class="text-right">
                                &euro;{{(this.getSubTotalPrice(order) / 100).toFixed(2)}}
                            </b-col>
                        </b-row>
                        <b-row class="py-1">
                            <b-col col>
                                Bezorgkosten
                            </b-col>
                            <b-col col lg="3" class="text-right">
                                &euro;{{(order.delivery_price / 100).toFixed(2)}}
                            </b-col>
                        </b-row>
                        <b-row class="py-1">
                            <b-col col>
                                <b>Totaal</b>
                            </b-col>
                            <b-col col lg="3" class="text-right">
                                <b>&euro;{{(this.getTotalPrice(order) / 100).toFixed(2)}}</b>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-col>
            </b-row>
        </section>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'PlacedOrder',
        data: function () {
            return {
                order: null,
            }
        },
        created() {
            // GET to order API by id
            axios.get(`${process.env.VUE_APP_API_URL || 'http://localhost:3000/'}api/orders/${this.$route.params.id}`).then((response) => {
                // Handle success
                this.order = response.data.data
            }).catch((error) => {
                console.log(error)
            })
        },
        methods: {
            // Get subtotal price of order
            getSubTotalPrice(order) {
                return order.order_lines.reduce((total, order_line) => {

                    // Add all extra_price if any
                    let extra_price = order_line.menu_rules.reduce((sum, rule) => {
                        return sum + rule.extra_price
                    }, 0)

                    // Return the total price
                    return total + ((order_line.price * order_line.quantity) + extra_price);
                }, 0);
            },

            // Get total price of order
            getTotalPrice(order) {
                return (this.getSubTotalPrice(order) + order.delivery_price)
            },
        }
    }
</script>

<style scoped>
    .placed-order .bgimage {
        background: url('../assets/header/banner-scooter.jpg');
        background-size: cover;
        /* background-position: right;
        background-repeat: no-repeat; */
    }
</style>